<template>
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 1L6 5.5L1.5 1"
      :stroke="getAssets.primaryBrandColor || '#000000'"
      stroke-width="2"
    />
  </svg>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DownArrow",
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>
